<template>
    <editor
        :id="id"
        :mode="mode_type"
        :type="contentType"
        :editing="editing"
        :item="form_data"
        @checkRouteForValidate="checkRouteForValidate"
        @reset="resetToPrew"
        @editPost="editPost"
        @createRevision="createRevision"
    >
        <template #contentFormFields>
            <keep-alive :max="10">
                <router-view
                    ref="content"
                    :form_data="form_data"
                    :loading="loading"
                    :fields="fields"
                    :validation-rules="validationRules"
                    :categories="selectCategories[contentType]"
                    :server-errors="serverErrors"
                    @update-data="getData"
                    @reset="reset"
                    @submit="submit"
                />
            </keep-alive>
        </template>
    </editor>
</template>

<script>
import customEditorMixins from '@/mixins/custom-editor/customEditorMixins'
import { mapTaskFields } from '@/store/modules/content/content-edit/tasks/index.js'

import { editItems } from '@/helpers/constants/custom-editor/task/editor-items'

import {
    ACTION_UPDATE_POST,
    ACTION_GET_POST,
    ACTION_CREATE_REVISION,
    ACTION_VALIDATE_SLUG,
    ACTION_CREATE_POST
} from '@/store/modules/content/content-edit/action-types'
import {
    RESET_EDIT_FORM_DATA,
    RESET_CREATED_FORM_DATA
} from '@/store/modules/content/content-edit/tasks/mutation-types'
// import {
//     ACTION_GET_CATEGORY_LIST_FOR_SELECT
// } from '@/store/modules/content/content-category/action-types'
import { createNamespacedHelpers, mapState } from 'vuex';
import { validationRules } from '@/validation/Tasks/Rules.js'

const {
    mapActions: mapContentEditActions
} = createNamespacedHelpers('contentTaskEdit');
const {
    mapMutations: mapContentEditMutations
} = createNamespacedHelpers('contentTaskEdit');
// const {
//     mapActions: mapContentСategoriesActions
// } = createNamespacedHelpers('contentCategory');

export default {
    name: 'EditTaskPage',
    mixins: [
        customEditorMixins
    ],
    computed: {
        ...mapTaskFields([
            'task',
            'task.form_data',
            'task.form_data.id',
            'task.default_form_data',
            'task.editing',
            'task.unfinished_session',
            'task.unfinished_url',
            'task.unfinished_type',
            'loading'
        ]),
        ...mapState('contentCategory', ['selectCategories']),
        editItems() {
            return editItems
        },
        revisionItems() {
            return this.editItems
        },
        createItems() {
            return this.editItems
        },
        validationRevisionRules() {
            return this.validationEditRules
        },
        validationCreateRules() {
            return this.validationEditRules
        },
        validationEditRules() {
            return validationRules
        }
    },
    activated() {
        this.editing = true
        if (this.$route.name !== 'create-post-task') {
            this.getData()
        }
    },
    methods: {
        // ...mapContentСategoriesActions([
        //     ACTION_GET_CATEGORY_LIST_FOR_SELECT
        // ]),
        ...mapContentEditActions([
            ACTION_UPDATE_POST,
            ACTION_GET_POST,
            ACTION_CREATE_REVISION,
            ACTION_VALIDATE_SLUG,
            ACTION_CREATE_POST
        ]),
        ...mapContentEditMutations([
            RESET_EDIT_FORM_DATA,
            RESET_CREATED_FORM_DATA
        ]),
        resetToPrew() {
            this.RESET_EDIT_FORM_DATA()
            if (this.$route.name !== 'create-post-task') {
                this.getData()
            }
            this.$bus.$emit('editingEnabled')
        },
        afterSubmitMethod() {
            return false
        }
    }
}
</script>
