export const editItems = [
    {
        name: 'subject',
        component: 'input',
        previewComponent: 'preview-text-field',
        label: 'Task name',
        placeholder: 'Task name',
        legend: 'Task name'
    },
    {
        name: 'locale',
        component: 'select',
        style: 'width: 49%; margin-right: 2%;',
        label: 'Localizaion',
        placeholder: 'Localizaion',
        legend: 'Localizaion'
    },
    {
        name: 'assignee_id',
        component: 'user-select',
        style: 'width: 49%;',
        label: 'Executor',
        placeholder: 'Executor',
        legend: 'Executor'
    },
    {
        name: 'post_type',
        component: 'task-type-seclect',
        style: 'width: 23.5%; margin-right: 2%;',
        label: 'Category',
        placeholder: 'Category',
        legend: 'Category'
    },
    {
        name: 'status',
        component: 'task-statuse-select',
        style: 'width: 23.5%; margin-right: 2%;',
        label: 'Status',
        placeholder: 'Status',
        legend: 'Status'
    },
    {
        name: 'deadline_at',
        component: 'date',
        style: 'width: 23.5%; margin-right: 2%;',
        label: 'Due date',
        placeholder: 'Due date',
        legend: 'Due date'
    },
    {
        name: 'words',
        component: 'input',
        style: 'width: 23.5%;',
        label: 'Wordcount',
        placeholder: 'Wordcount',
        legend: 'Min wordcount'
    },
    {
        name: 'post_links',
        component: 'inputLink',
        style: 'width: 49%; margin-right: 2%;',
        label: 'Link',
        placeholder: 'Link',
        legend: 'Link'
    },
    {
        name: 'priority',
        component: 'priorities-select',
        style: 'width: 24%;',
        label: 'Priority',
        placeholder: 'Priority',
        legend: 'Priority'
    },
    {
        name: 'description',
        component: 'content',
        label: 'Content',
        placeholder: 'Content',
        legend: 'Content'
    }
]
