import {
    required
} from 'vuelidate/lib/validators';

export const validationRules = {
    form_data: {
        post_type: {
            required
        },
        subject: {
            required
        },
        description: {
            required
        },
        priority: {
            required
        },
        status: {
            required
        },
        deadline_at: {
            required
        },
        assignee_id: {},
        words: {
            required
        },
        post_links: {},
        locale: {
            required
        }
    }
};
